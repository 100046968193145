import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout title="Say hello to Quak">
    <SEO
      title="Awesome Audio Notifications"
      keywords={[`sonos`, `audio`, `notifications`]}
    />

    <p>- What is Quak? - GIF/Video demo - What you can to with Quak</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>

    <p>
      <Link to="/sounds/">Sounds</Link>
    </p>

    <p>
      <Link to="/voices/">Voices</Link>
    </p>

    <h1>Typography.js</h1>
    <p>A powerful toolkit for building websites with beautiful design.</p>
    <h2>Install</h2>
    <p>
      <code>npm install typography</code>
    </p>
    <h2>Demo/playground</h2>
    <p>
      <a href="http://kyleamathews.github.io/typography.js">
        http://kyleamathews.github.io/typography.js
      </a>
    </p>
    <h2>Why</h2>
    <p>
      Typography is a complex <em>system</em> of interrelated styles. 100s of
      style declarations on dozens of elements must be in harmonious order.
      Trying one design change can mean making dozens of tedious recalculations
      and CSS value changes. Creating new Typography themes with CSS feels hard.
    </p>
  </Layout>
)

export default IndexPage
